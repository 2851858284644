{
  "name": "stsweb",
  "version": "0.8.0",
  "description": "",
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1",
    "start": "parcel src/index.html --open",
    "build": "parcel build src/index.html --dist-dir buildoutput/ --no-scope-hoist",
    "dockerbuild": "docker build -f Dockerfile.prod -t sts:latest .",
    "dockertag": "docker tag sts:latest registry.digitalocean.com/sts-docker-repo/sts:latest",
    "dockerpush": "docker push registry.digitalocean.com/sts-docker-repo/sts:latest",
    "publish": "npm run dockerbuild && npm run dockertag && npm run dockerpush"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "devDependencies": {
    "@types/node": "^17.0.36",
    "@types/react": "^18.0.15",
    "@types/react-csv": "^1.1.2",
    "@types/react-datepicker": "^4.4.1",
    "@types/react-dom": "^18.0.6",
    "buffer": "^5.5.0",
    "events": "^3.3.0",
    "node-sass": "^7.0.1",
    "os-browserify": "^0.3.0",
    "parcel": "^2.8.0",
    "path-browserify": "^1.0.1",
    "postcss": "^8.4.13",
    "prettier": "2.5.1",
    "process": "^0.11.10",
    "tailwindcss": "^3.0.24",
    "typescript": "^4.5.5"
  },
  "dependencies": {
    "@react-pdf/renderer": "^3.0.1",
    "@tailwindcss/forms": "^0.5.2",
    "axios": "^0.26.1",
    "date-fns": "^2.28.0",
    "dotenv": "^16.0.1",
    "generate-password-browser": "^1.1.0",
    "jwt-decode": "^3.1.2",
    "lodash": "^4.17.21",
    "prop-types": "^15.8.1",
    "react": "^18.0.2",
    "react-countdown-circle-timer": "^3.0.9",
    "react-csv": "^2.2.2",
    "react-datepicker": "^4.7.0",
    "react-dom": "^18.2.0",
    "react-loading-icons": "^1.0.8",
    "react-qr-code": "^2.0.11",
    "react-router-dom": "^6.2.1",
    "react-select": "^5.4.0",
    "react-toastify": "^8.2.0",
    "yup": "^0.32.11",
    "zustand": "^4.0.0-rc.1"
  }
}

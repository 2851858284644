import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  format,
  parseISO,
  differenceInDays,
  addDays,
  isWithinInterval,
  startOfDay,
  endOfDay,
  parse,
} from "date-fns";
import fi from "date-fns/locale/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import debounce from "lodash/debounce";
import { api } from "../../api/axiosCalls";

import useTable from "../../hooks/useTable";
import { useSettingsStore } from "../../stores/UserSettings";
import { PageList } from "../../components/common/PageList";
import { PageSizeSelect } from "../../components/common/PageSizeSelect";
import { filterByIncludes } from "../../api/filters/common";

registerLocale("fi", fi);
export interface UpcomingRow {
  id: string;
  name: string;
  zip: string;
  city: string;
  last_inspection_date: string;
  inspection_frequency: string;
  next_inspection_date: string;
  customer_active: number | null;
}

export const parseSQLDate = (date: string) => {
  return parseISO(date.split("T")[0]);
};

export const UpcomingInspections: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();

  const { resultsPerPageUpcoming, setResultsPerPageUpcoming } =
    useSettingsStore();

  const [page, setPage] = useState(1);
  const [allUpcoming, setAllUpcoming] = useState<UpcomingRow[]>([]);
  const [displayedUpcomingRows, setDisplayedUpcomingRows] = useState<
    UpcomingRow[]
  >([]);
  const [uniFilter, setUnitFilter] = useState("");

  const [onlyActiveCustomers, setOnlyActiveCustomers] = useState(true);

  const { slice, range } = useTable(
    displayedUpcomingRows,
    page,
    resultsPerPageUpcoming
  );

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(addDays(new Date(), 90));

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnitFilter(e.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const fetchAllUpcomingInspections = async () => {
    const allUpcomingInspectionsResponse = await api.get(
      "/reports/allupcominginspections"
    );
    if (allUpcomingInspectionsResponse.status === 200) {
      const { data } = allUpcomingInspectionsResponse;
      setAllUpcoming(data);
    }
  };

  useEffect(() => {
    fetchAllUpcomingInspections();
  }, []);

  useEffect(() => {
    const textFiltered =
      uniFilter.length > 1
        ? filterByIncludes(allUpcoming, uniFilter)
        : allUpcoming;
    const packedRows = packLocations(textFiltered);
    const fitsDatesRows = fitsDateRange(packedRows);
    const activeCustomerFilterered = onlyActiveCustomers
      ? hasActiveCustomer(fitsDatesRows)
      : fitsDatesRows;

    const sorted = activeCustomerFilterered.sort((a, b) => {
      const au = new Date(a.next_inspection_date);
      const bu = new Date(b.next_inspection_date);
      if (au < bu) {
        return -1;
      }
      if (au > bu) {
        return 1;
      }
      return 0;
    });

    setDisplayedUpcomingRows([...sorted]);
  }, [uniFilter, allUpcoming, startDate, endDate, onlyActiveCustomers]);

  const packLocations = (data: UpcomingRow[]) => {
    const seen = new Set();
    return data.filter((row) => {
      const duplicate = seen.has(`${row.id} ${row.next_inspection_date}`);
      seen.add(`${row.id} ${row.next_inspection_date}`);
      return !duplicate;
    });
  };

  const fitsDateRange = (data: UpcomingRow[]) => {
    return data.filter((row) => {
      try {
        const result = isWithinInterval(
          parseSQLDate(row.next_inspection_date),
          {
            start: startDate || new Date(),
            end: endDate || addDays(new Date(), 90),
          }
        );
        return result;
      } catch {
        return false;
      }
    });
  };

  const hasActiveCustomer = (data: UpcomingRow[]) => {
    return data.filter((row) => {
      return row.customer_active === 1 || row.customer_active === null;
    });
  };

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
  };

  const headers = [
    "Nimi",
    "Postinro",
    "Postitmp",
    "Viimeisin tarkastus",
    "Seuraava laskettu tarkastus",
    "Päiviä tarkistukseen",
    "",
  ];

  return (
    <>
      <main className="py-8 mx-5">
        <div className="flex justify-between">
          <h4 className="text-black font-bold text-4xl">Tulevat</h4>
        </div>
        <div className="mt-4 -mb-3">
          <div className="not-prose relative bg-slate-100 rounded-xl overflow-x-auto">
            <div className="relative rounded-xl">
              <div className="shadow-sm my-2">
                <div className="p-4">
                  <div className="flex justify-between flex-wrap">
                    <div className="relative mt-1">
                      <div className="flex flex-col mb-2">
                        <span className="text-left">Haku</span>
                        <div className="absolute inset-y-12 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="text"
                          className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-80 pl-10 p-2.5 mr-2"
                          placeholder="Hae kohteita"
                          onChange={debouncedChangeHandler}
                        />
                      </div>
                      <div className="relative mt-1">
                        <label className="mr-2">
                          Vain aktiiviset asiakkuudet
                        </label>
                        <input
                          className="-mt-1"
                          type="checkbox"
                          checked={onlyActiveCustomers}
                          onClick={() => {
                            setOnlyActiveCustomers(!onlyActiveCustomers);
                          }}
                        />
                      </div>
                    </div>
                    {/*datepickers*/}
                    <div className="flex flex-col mb-2">
                      <span className="text-left">Aikarajaus</span>
                      <div className="flex items-center">
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-50">
                            <svg
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-100"
                            locale="fi"
                            dateFormat="dd/MM/yyyy"
                            selected={startDate}
                            disabledKeyboardNavigation
                            onChange={(date) => {
                              setStartDate(startOfDay(date || new Date()));
                            }}
                          />
                        </div>
                        <span className="mx-4 text-gray-500">-</span>
                        <div className="relative">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-50">
                            <svg
                              className="w-5 h-5 text-gray-500 dark:text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                          <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-100"
                            locale="fi"
                            dateFormat="dd/MM/yyyy"
                            selected={endDate}
                            disabledKeyboardNavigation
                            onChange={(date) => {
                              setEndDate(endOfDay(date || new Date()));
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/*datepickers end*/}
                    <div>
                      <div className="flex justify-between">
                        <div>
                          <PageSizeSelect
                            pageSize={resultsPerPageUpcoming}
                            setPageSize={setResultsPerPageUpcoming}
                          />
                        </div>
                      </div>
                      <div>
                        <span className="text-xs">{`Sivu ${page} / ${
                          range[range.length - 1]
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                </div>
                <div className="overflow-x">
                  <table
                    className="table-auto overflow-scroll w-full"
                    style={{
                      minHeight: 300,
                    }}
                  >
                    <thead>
                      <tr>
                        {headers.map((headerName) => (
                          <th className={styles.th} key={headerName}>
                            {headerName}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {slice.map((row: UpcomingRow) => (
                        <tr
                          key={row.id + row.next_inspection_date}
                          className="hover:bg-green-100 cursor-pointer"
                        >
                          <td className={styles.td + " font-bold"}>
                            {row.name}
                          </td>
                          <td className={styles.td}>{row.zip}</td>
                          <td className={styles.td}>{row.city}</td>
                          <td className={styles.td}>
                            {format(
                              parseISO(row.last_inspection_date),
                              "dd/MM/yyyy"
                            ) || ""}
                          </td>
                          <td className={styles.td}>
                            {format(
                              parseISO(row.next_inspection_date),
                              "dd/MM/yyyy"
                            ) || ""}
                          </td>
                          <td className={styles.td}>
                            {differenceInDays(
                              parseISO(row.next_inspection_date),
                              new Date()
                            )}
                          </td>
                          <td className={styles.td}>
                            <a href={`/locations/${row.id}`}>
                              <button className="bg-blue-500 hover:bg-green-700 text-white font-bold py-2 px-8 border border-green-700 rounded">
                                Avaa
                              </button>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
          </div>
          <PageList pageSetter={setPage} page={page} range={range} />
        </div>
      </main>
    </>
  );
};

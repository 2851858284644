import { startOfDay } from "date-fns";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { AddExtinguisherModal } from "../screens/extinguishers/AddExtinguishersModal";
import { ExtinguisherRow } from "../screens/extinguishers/ExtinguishersView";
import { PerformInspectionModal } from "../screens/extinguishers/PerformInspectionModal";

interface ThreePartActionButtonProps {
  row: ExtinguisherRow;
  locationId: string;
  refreshAction: () => void;
}

export const ThreePartActionButton: React.FC<ThreePartActionButtonProps> = ({
  row,
  locationId,
  refreshAction,
}) => {
  const [showInspectModal, setShowInspectModal] = useState(false);
  const [showExtinguisherCopyModal, setShowExtinguisherCopyModal] =
    useState(false);

  return (
    <>
      <>
        <button
          className="rounded-l-lg p-2 shadow-sm bg-slate-300 hover:bg-slate-100 text-xs"
          onClick={() => {
            setShowExtinguisherCopyModal(true);
          }}
        >
          📚
        </button>
        <a href={`/extinguishers/${row.id}?origin=/locations/${locationId}`}>
          <button
            style={{ minWidth: 40 }}
            className="rounded-none p-2 shadow-sm bg-slate-200 hover:bg-slate-50 border border-l border-r"
          >
            🔍
          </button>
        </a>

        <button
          onClick={() => {
            setShowInspectModal(true);
          }}
          className="rounded-r-lg p-2 shadow-sm bg-slate-300 hover:bg-slate-100 text-xs"
        >
          📐
        </button>
      </>
      {showInspectModal && (
        <PerformInspectionModal
          showAction={setShowInspectModal}
          refreshAction={refreshAction}
          extinguisher={{ ...row, location_id: locationId }}
          quick={true}
        />
      )}
      {showExtinguisherCopyModal && (
        <AddExtinguisherModal
          showAction={setShowExtinguisherCopyModal}
          data={row}
        />
      )}
    </>
  );
};

import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { api } from "../../api/axiosCalls";
import { MainWrapper } from "../../components/common/MainWrapper";
import { ConfirmModal } from "../../components/common/ConfirmModal";

export interface EventTypeRow {
  id: string;
  event_type: string;
}

const styles = {
  th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs",
  td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold my-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

interface ModalFormProps {
  showAction: (show: boolean) => void;
  updateList: () => void;
  data: EventTypeRow | null;
}

export const AddEventTypeModal: React.FunctionComponent<ModalFormProps> = ({
  showAction,
  updateList,
  data,
}) => {
  const [formData, setFormData] = useState({
    eventType: data?.event_type,
    id: data?.id,
  });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  let schema = yup.object().shape({
    eventType: yup.string().required(),
  });

  const createEventType = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createTypeResponse = await api.put("/events/createtype", {
        eventType: formData.eventType,
      });
      if (createTypeResponse.status === 200) {
        showAction(false);
        updateList();
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const editEventType = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createTypeResponse = await api.put("/events/updatetype", {
        ...formData,
      });
      if (createTypeResponse.status === 200) {
        showAction(false);
        updateList();
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-2xl font-semibold pr-10">{`${
                data !== null
                  ? "Muokkaa tapahtumatyyppiä"
                  : "Luo uusi tapahtumatyyppi"
              }`}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto bg-slate-200">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Tapahtumakuvaus
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="input"
                      defaultValue={data?.event_type || ""}
                      onChange={(e) => {
                        handleEdit(e.target.value, "eventType");
                      }}
                    />
                    {validationErrors.includes("type") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        tyyppi vaadittu
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => (data ? editEventType() : createEventType())}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export const EventsAdminView: React.FunctionComponent<{}> = () => {
  const [eventTypes, setEventTypes] = useState<EventTypeRow[]>([]);
  const [editedEventType, setEditedEventType] = useState<EventTypeRow | null>(
    null
  );

  const [showEventTypeModal, setShowEventTypeModal] = useState(false);

  const [showEventTypeConfirm, setShowEventTypeConfirm] = useState(false);

  const [confirmAction, setConfirmAction] = useState<
    null | (() => Promise<void>)
  >(null);

  const fetchEventTypes = async () => {
    const eventTypesResponse = await api.get("/events/select");
    if (eventTypesResponse.status === 200) {
      const { data } = eventTypesResponse;
      setEventTypes(data);
    }
  };

  const deleteEventType = async (id: string) => {
    const finalDelete = async () => {
      const deleteBrandResponse = await api.delete("/events/deletetype", {
        data: { id },
      });
      if (deleteBrandResponse.status === 200) {
        fetchEventTypes();
      } else {
        // error handling
      }
    };

    setShowEventTypeConfirm(true);
    setConfirmAction(() => finalDelete);
  };

  useEffect(() => {
    fetchEventTypes();
  }, []);

  return (
    <MainWrapper titleText="Tapahtumatyyppien hallinnointi">
      <div className="flex items-center flex-col py-5">
        <button
          className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm w-fit"
          onClick={() => {
            setEditedEventType(null);
            setShowEventTypeModal(true);
          }}
        >
          Lisää uusi
        </button>
        <div className="overflow-y-auto" style={{ maxHeight: "60vh" }}>
          <table
            className="table-auto overflow-scroll"
            style={{ minWidth: "500px", width: "60%" }}
          >
            <thead>
              <tr>
                <th className={styles.th}>ID</th>
                <th className={styles.th}>Tapahtumakuvaus</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {eventTypes.map((row: EventTypeRow) => (
                <tr key={row.id}>
                  <td className={styles.td + " font-bold"}>{row.id}</td>
                  <td className={styles.td}>{row.event_type}</td>
                  <td className={styles.td + " text-right"}>
                    <button
                      className="bg-green-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                      onClick={() => {
                        setEditedEventType(row);
                        setShowEventTypeModal(true);
                      }}
                    >
                      Muokkaa
                    </button>
                    <button
                      className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 mr-2 text-xm"
                      onClick={() => deleteEventType(row.id)}
                    >
                      Poista
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showEventTypeModal ? (
        <AddEventTypeModal
          showAction={setShowEventTypeModal}
          updateList={fetchEventTypes}
          data={editedEventType || null}
        />
      ) : null}
      {showEventTypeConfirm && confirmAction ? (
        <ConfirmModal
          showAction={setShowEventTypeConfirm}
          header="Tapahtumatyypin poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän tapahtumatyypin?"
          additionalInfo="❗️ Tapahtumatyypin käyttö uusissa tapahtumissa ei ole enään mahdollista tämän jälkeen. ❗️"
          confirmAction={confirmAction}
        />
      ) : null}
    </MainWrapper>
  );
};

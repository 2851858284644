import React, { useState, useEffect } from "react";

import fi from "date-fns/locale/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import * as yup from "yup";
import { api } from "../../api/axiosCalls";
import { useNavigate } from "react-router-dom";
import { ExtinguisherRow } from "./ExtinguishersView";
import { startOfDay } from "date-fns";
import { parseSQLDate, rtzSQL } from "../../utils/datef";
registerLocale("fi", fi);

interface PerformInspectionModalProps {
  showAction: (show: boolean) => void;
  extinguisher: ExtinguisherRow;
  quick?: boolean;
  refreshAction?: () => void;
}

export interface AccountRow {
  id: string;
  manual_identifier: string;
  first_name: string;
  last_name: string;
}

export interface EventTypeRow {
  id: string;
  event_type: string;
  created_on: string;
  deleted: string;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

export const PerformInspectionModal: React.FunctionComponent<
  PerformInspectionModalProps
> = ({ showAction, extinguisher, quick, refreshAction }) => {
  const [startDate, setStartDate] = useState<Date | null>(
    quick ? new Date() : null
  );

  const [accounts, setAccounts] = useState<AccountRow[] | null>(null);
  const [eventTypes, setEventTypes] = useState<EventTypeRow[] | null>(null);
  const [formData, setFormData] = useState<any>({
    notes: "",
    extinguisher: extinguisher.id,
    location: extinguisher.location_id,
    inspector: localStorage.getItem("user_id") || undefined,
    eventType: quick ? "1" : undefined,
    inspectionDate: quick ? rtzSQL(new Date()) : undefined,
  });
  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  useEffect(() => {
    const fetchAccounts = async () => {
      const accountsResponse = await api.get("/accounts/select");
      if (accountsResponse.status === 200) {
        const { data } = accountsResponse;
        setAccounts(data);
      }
    };

    fetchAccounts();
  }, []);

  useEffect(() => {
    const fetchEventTypes = async () => {
      const eventTypesResponse = await api.get("/events/select");
      if (eventTypesResponse.status === 200) {
        const { data } = eventTypesResponse;
        setEventTypes(data);
      }
    };

    fetchEventTypes();
  }, []);

  const handleEdit = (value: string, field: string) => {
    setFormData({ ...formData, [field]: value });
  };

  let schema = yup.object().shape({
    extinguisher: yup.string().required(),
    location: yup.string().required(),
    eventType: yup.string().required(),
    inspectionDate: yup.string().required(),
    inspector: yup.string().required(),
  });

  const createInspection = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createInspectionResponse = await api.put("/events/create", {
        event: formData,
      });
      if (createInspectionResponse.status === 200) {
        showAction(false);
        refreshAction && refreshAction();
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold pr-10">{`${extinguisher.qr} ${extinguisher.type} ${extinguisher.brand} ${extinguisher.year_of_manufacture}`}</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <h3 className="text-xl font-semibold mb-10">{`${extinguisher.name}`}</h3>
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Tapahtuma</label>
                    <select
                      className="form-select form-select-lg mb-3
                      appearance-none
                      block
                      w-full
                      px-4
                      py-2
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => {
                        handleEdit(e.target.value, "eventType");
                      }}
                    >
                      <option selected></option>
                      {eventTypes &&
                        eventTypes.map((eventType) => (
                          <option
                            value={eventType.id}
                            selected={quick && eventType.id == "1"}
                          >
                            {`${eventType.event_type}`}
                          </option>
                        ))}
                    </select>
                    {validationErrors.includes("eventType") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Tapahtuma vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>
                      Tapahtumapäivämäärä
                    </label>
                    <DatePicker
                      className={styles.inputDefault}
                      locale="fi"
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      disabledKeyboardNavigation
                      onChange={(date) => {
                        date && setStartDate(startOfDay(date));
                        date && handleEdit(rtzSQL(date), "inspectionDate");
                      }}
                    />
                    {validationErrors.includes("inspectionDate") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Tapahtumapäivämäärä vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-8/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Suorittaja</label>
                    <select
                      className="form-select form-select-lg mb-3
                      appearance-none
                      block
                      w-full
                      px-4
                      py-2
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => {
                        handleEdit(e.target.value, "inspector");
                      }}
                    >
                      <option selected></option>
                      {accounts &&
                        accounts.map((account) => (
                          <option
                            value={account.id}
                            selected={
                              account.manual_identifier ===
                              localStorage.getItem("user_manual_identifier")
                            }
                          >
                            {`${account.first_name} ${account.last_name}`}
                          </option>
                        ))}
                    </select>
                    {validationErrors.includes("inspector") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        suorittaja vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-12/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Huomiot</label>
                    <textarea
                      className={styles.inputDefault}
                      onChange={(e) => {
                        handleEdit(e.target.value, "notes");
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => createInspection()}
              >
                Suorita
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

import React, { useEffect, useState } from "react";

import fi from "date-fns/locale/fi";
import DatePicker, { registerLocale } from "react-datepicker";
import { api } from "../../api/axiosCalls";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { BrandRow, ModelRow, SizeRow, TypeRow } from "./ExtinguishersAdminView";
import { LocationRow } from "../locations/LocationsView";
import { parseISO, startOfDay } from "date-fns";
import { AccountRow } from "./PerformInspectionModal";
import { EventTypeRow } from "../events/EventsAdmin";
import { parseSQLDate, rtzSQL } from "../../utils/datef";
import { isLiquidFireExtinguisherType } from "../../utils/isLiquidExtinguisher";

registerLocale("fi", fi);
interface AddExtinguisherFormProps {
  showAction: (show: boolean) => void;
  locationId?: string;
  data?: any;
}

const styles = {
  labelDefault:
    "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
  inputDefault:
    "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
};

export const AddExtinguisherModal: React.FunctionComponent<
  AddExtinguisherFormProps
> = ({ showAction, data }) => {
  const [formData, setFormData] = useState<any>({
    qr: 99900000,
    location: data?.location_id,
    type: data?.type_id,
    brand: data?.brand_id,
    model: data?.model,
    size: data?.size_id,
    placement: data?.placement,
    lastInspectionDate: data?.last_inspection_date,
    inspectionFrequency: data?.inspection_frequency || "1",
    yearOfManufacture: data?.year_of_manufacture,
    yearOfHydrostaticTest: data?.year_of_hydrostatic_test,
    yearOfLiquidReplacement: data?.year_of_liquid_replacement,
    inspector: data?.inspector
      ? data.inspector
      : localStorage.getItem("user_id"),
    eventType: data?.resolved_event_type,
  });

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [qrTaken, setQrTaken] = useState(false);

  let schema = yup.object().shape({
    qr: yup.string().required(),
    location: yup.string().required(),
    placement: yup.string().required(),
    type: yup.string().required(),
    brand: yup.string().required(),
    size: yup.string().required(),
    lastInspectionDate: yup.string().required(),
    inspectionFrequency: yup.string().required(),
    inspector: yup.string().required(),
    yearOfManufacture: yup.string().required(),
    eventType: yup.string().required(),
  });

  const navigate = useNavigate();

  const [allLocations, setAllLocations] = useState<LocationRow[]>();
  const [allTypes, setAllTypes] = useState<TypeRow[]>();
  const [allBrands, setAllBrands] = useState<BrandRow[]>();
  const [allModels, setAllModels] = useState<ModelRow[]>();
  const [allSizes, setAllSizes] = useState<SizeRow[]>();
  const [accounts, setAccounts] = useState<AccountRow[]>();
  const [eventTypes, setEventTypes] = useState<EventTypeRow[] | null>(null);

  const [liquidDateVisible, setLiquidDateVisible] = useState(
    formData?.type ? isLiquidFireExtinguisherType(formData?.type) : false
  );

  const [allowedSizes, setAllowedSizes] = useState<SizeRow[]>([]);
  const [allowedModels, setAllowedModels] = useState<ModelRow[]>([]);

  useEffect(() => {
    formData.type &&
      allSizes &&
      setAllowedSizes(allSizes.filter((size) => size.type == formData.type));
  }, [allSizes, formData.type]);

  useEffect(() => {
    formData.type &&
      formData.brand &&
      formData.size &&
      allModels &&
      setAllowedModels(
        allModels.filter(
          (model) =>
            (model.type == formData.type &&
              model.size == formData.size &&
              model.brand == formData.brand) ||
            model.always_available == "1"
        )
      );
  }, [allSizes, allModels, allBrands, formData]);

  const fetchAllLocations = async () => {
    const allLocationsResponse = await api.get("/locations");
    if (allLocationsResponse.status === 200) {
      const { data } = allLocationsResponse;
      setAllLocations(data);
    }
  };

  const fetchAllTypes = async () => {
    const allTypesResponse = await api.get("/extinguishers/types");
    if (allTypesResponse.status === 200) {
      const { data } = allTypesResponse;
      setAllTypes(data);
    }
  };

  const fetchAllBrands = async () => {
    const allBrandsResponse = await api.get("/extinguishers/brands");
    if (allBrandsResponse.status === 200) {
      const { data } = allBrandsResponse;
      setAllBrands(data);
    }
  };

  const fetchAllModels = async () => {
    const allModelsResponse = await api.get("/extinguishers/models");
    if (allModelsResponse.status === 200) {
      const { data } = allModelsResponse;
      setAllModels(data);
    }
  };

  const fetchAllSizes = async () => {
    const allSizesResponse = await api.get("/extinguishers/sizes");
    if (allSizesResponse.status === 200) {
      const { data } = allSizesResponse;
      setAllSizes(data);
    }
  };

  const fetchAccounts = async () => {
    const accountsResponse = await api.get("/accounts/select");
    if (accountsResponse.status === 200) {
      const { data } = accountsResponse;
      setAccounts(data);
    }
  };

  const fetchEventTypes = async () => {
    const eventTypesResponse = await api.get("/events/select");
    if (eventTypesResponse.status === 200) {
      const { data } = eventTypesResponse;
      setEventTypes(data);
    }
  };

  useEffect(() => {
    fetchAllLocations();
    fetchAllTypes();
    fetchAllBrands();
    fetchAllModels();
    fetchAllSizes();
    fetchAccounts();
    fetchEventTypes();
  }, []);

  const handleEdit = (value: string | number, field: string) => {
    if (field == "type") {
      if (isLiquidFireExtinguisherType(value)) {
        setLiquidDateVisible(true);
        setFormData({ ...formData, [field]: value });
      } else {
        setLiquidDateVisible(false);
        setFormData({
          ...formData,
          [field]: value,
          yearOfLiquidReplacement: undefined,
        });
      }
    } else {
      setFormData({ ...formData, [field]: value });
    }
  };

  const createExtinguisher = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      const createExtinguisherResponse = await api.put(
        "/extinguishers/create",
        {
          extinguisher: formData,
        }
      );

      if (createExtinguisherResponse.status === 226) {
        setQrTaken(true);
      }

      if (createExtinguisherResponse.status === 200) {
        if (formData.eventType) {
          const createInspectionResponse = await api.put("/events/create", {
            event: {
              eventType: formData.eventType,
              extinguisher: createExtinguisherResponse.data.id,
              inspectionDate: formData.lastInspectionDate,
              inspector: formData.inspector,
              location: formData.location,
              notes: "",
            },
          });

          if (createInspectionResponse.status === 200) {
            navigate(
              `/extinguishers/${createExtinguisherResponse.data.id}${
                data ? `?origin=/locations/${formData.location}` : ""
              }`
            );
          }
        } else {
          navigate(`/extinguishers/${createExtinguisherResponse.data.id}`);
        }
      }
    } catch (e) {
      console.log(e);
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  const defaultSelectedLocation = allLocations?.find(
    (item: LocationRow) => item.id === data?.location_id
  );

  const defaultSelectedType = allTypes?.find(
    (item: TypeRow) => item.id == data?.type_id
  );

  const defaultSelectedBrand = allBrands?.find(
    (item: BrandRow) => item.id == data?.brand_id
  );

  const defaultSelectedModel = allModels?.find(
    (item: ModelRow) => item.id == data?.model
  );

  const defaultSelectedSize = allSizes?.find(
    (item: SizeRow) => item.id == data?.size_id
  );

  return allBrands &&
    allLocations &&
    allModels &&
    allSizes &&
    allTypes &&
    accounts ? (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">Uusi sammutin</h3>
              <button
                className="p-1 ml-auto border-0 float-right text-3xl leading-none"
                onClick={() => showAction(false)}
              >
                <span className="text-black h-6 w-6 text-lg block">❌</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <form className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      QR
                    </label>
                    <input
                      className={styles.inputDefault}
                      defaultValue={"99900000"}
                      type="number"
                      onChange={(e) => {
                        handleEdit(e.target.value, "qr");
                      }}
                    />
                    {validationErrors.includes("qr") && (
                      <div className="ml-1 mt-1 text-pink-700">QR vaadittu</div>
                    )}
                    {qrTaken && (
                      <div className="ml-1 mt-1 text-pink-700">
                        QR-koodi on jo käytetty, kokeile uudestaan toisella
                        koodilla.
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Kohde
                    </label>
                    <Select
                      options={
                        allLocations &&
                        allLocations.map((item: LocationRow) => ({
                          value: item.id,
                          label: `${item.name} - ${item.address} ${item.city} ${item.zip}`,
                        }))
                      }
                      defaultValue={
                        defaultSelectedLocation
                          ? {
                              value: defaultSelectedLocation.id,
                              label: `${defaultSelectedLocation.name} - ${defaultSelectedLocation.address} ${defaultSelectedLocation.city} ${defaultSelectedLocation.zip}`,
                            }
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => {
                        e && handleEdit(e.value, "location");
                      }}
                    />
                    {validationErrors.includes("location") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Kohde vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Sijainti </label>
                    <input
                      className={styles.inputDefault}
                      defaultValue={data?.placement}
                      type="text"
                      min={0}
                      onChange={(e) => {
                        handleEdit(e.target.value, "placement");
                      }}
                    />
                    {validationErrors.includes("placement") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Sijainti vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Tyyppi </label>
                    <Select
                      options={
                        allTypes &&
                        allTypes.map((item: TypeRow) => ({
                          value: item.id,
                          label: item.type,
                        }))
                      }
                      defaultValue={
                        defaultSelectedType
                          ? {
                              value: defaultSelectedType.id,
                              label: defaultSelectedType.type,
                            }
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => {
                        e && handleEdit(e.value, "type");
                      }}
                    />
                  </div>
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Merkki </label>
                    <Select
                      options={
                        allBrands &&
                        allBrands.map((item: BrandRow) => ({
                          value: item.id,
                          label: item.brand,
                        }))
                      }
                      defaultValue={
                        defaultSelectedBrand
                          ? {
                              value: defaultSelectedBrand.id,
                              label: defaultSelectedBrand.brand,
                            }
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => {
                        e && handleEdit(e.value, "brand");
                      }}
                    />
                    {validationErrors.includes("brand") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Merkki vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Koko </label>
                    <Select
                      isDisabled={!formData?.type}
                      options={
                        allowedSizes &&
                        allowedSizes.map((item: SizeRow) => ({
                          value: item.id,
                          label: `${item.size} ${item.unit}`,
                        }))
                      }
                      defaultValue={
                        defaultSelectedSize
                          ? {
                              value: defaultSelectedSize.id,
                              label: `${defaultSelectedSize.size} ${defaultSelectedSize.unit}`,
                            }
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => {
                        e &&
                          setFormData({
                            ...formData,
                            size: e.value,
                          });
                      }}
                    />
                    {validationErrors.includes("size") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Koko vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Malli</label>
                    <Select
                      isDisabled={
                        !formData?.type || !formData?.size || !formData?.brand
                      }
                      options={
                        allowedModels &&
                        allowedModels.map((item: ModelRow) => ({
                          value: { model: item.id },
                          label: `${item.model}`,
                        }))
                      }
                      defaultValue={
                        defaultSelectedModel
                          ? {
                              value: { model: defaultSelectedModel.id },
                              label: `${defaultSelectedModel.model}`,
                            }
                          : undefined
                      }
                      placeholder=""
                      onChange={(e) => {
                        e &&
                          setFormData({
                            ...formData,
                            model: e.value.model,
                          });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-2">
                  {liquidDateVisible && (
                    <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                      <label className={styles.labelDefault}>
                        Nesteenvaihtovuosi
                      </label>
                      <input
                        className={styles.inputDefault}
                        type="number"
                        value={formData.yearOfLiquidReplacement}
                        onChange={(e) => {
                          handleEdit(e.target.value, "yearOfLiquidReplacement");
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-7/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>
                      Tarkistuspäivämäärä
                    </label>
                    <DatePicker
                      className={styles.inputDefault}
                      locale="fi"
                      dateFormat="dd/MM/yyyy"
                      selected={
                        formData.lastInspectionDate
                          ? parseISO(formData.lastInspectionDate)
                          : null
                      }
                      disabledKeyboardNavigation
                      onChange={(date) => {
                        date && handleEdit(rtzSQL(date), "lastInspectionDate");
                      }}
                    />
                    {validationErrors.includes("lastInspectionDate") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Tarkistuspäivämäärä vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-5/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>Tarkastusväli</label>
                    <select
                      className="form-select form-select-lg mb-3
                       appearance-none
                       block
                       w-full
                       px-4
                       py-2
                       text-base
                       font-normal
                       text-gray-700
                       bg-white bg-clip-padding bg-no-repeat
                       border border-solid border-gray-300
                       rounded
                       transition
                       ease-in-out
                       m-0
                       focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      defaultValue={formData.inspectionFrequency}
                      onChange={(e) => {
                        handleEdit(e.target.value, "inspectionFrequency");
                      }}
                    >
                      <option value="1">1 vuosi</option>
                      <option value="2">2 vuotta</option>
                    </select>

                    {validationErrors.includes("inspectionFrequency") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Tarkastusväli vaadittu
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>
                      Valmistusvuosi
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="number"
                      defaultValue={formData.yearOfManufacture}
                      onChange={(e) => {
                        handleEdit(e.target.value, "yearOfManufacture");
                      }}
                    />
                    {validationErrors.includes("yearOfManufacture") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Valmistusvuosi vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full md:w-6/12 px-3 mb-6 md:mb-0">
                    <label className={styles.labelDefault}>
                      Koeponnistusvuosi
                    </label>
                    <input
                      className={styles.inputDefault}
                      type="number"
                      defaultValue={formData.yearOfHydrostaticTest}
                      onChange={(e) => {
                        handleEdit(e.target.value, "yearOfHydrostaticTest");
                      }}
                    />
                  </div>
                  <div className="w-full px-3">
                    <label className={styles.labelDefault}>Tapahtuma</label>
                    <select
                      className="form-select form-select-lg mb-3
                      appearance-none
                      block
                      w-full
                      px-4
                      py-2
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => {
                        handleEdit(e.target.value, "eventType");
                      }}
                    >
                      <option selected></option>
                      {eventTypes &&
                        eventTypes.map((eventType) => (
                          <option
                            value={eventType.id}
                            selected={
                              data?.resolved_event == eventType.event_type
                            }
                          >
                            {`${eventType.event_type}`}
                          </option>
                        ))}
                    </select>
                    {validationErrors.includes("eventType") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Tapahtuma vaadittu
                      </div>
                    )}
                  </div>
                  <div className="w-full px-3">
                    <label
                      className={styles.labelDefault}
                      htmlFor="grid-password"
                    >
                      Tarkastaja
                    </label>
                    <select
                      className="form-select form-select-lg mb-3
                      appearance-none
                      block
                      w-full
                      px-4
                      py-2
                      text-base
                      font-normal
                      text-gray-700
                      bg-white bg-clip-padding bg-no-repeat
                      border border-solid border-gray-300
                      rounded
                      transition
                      ease-in-out
                      m-0
                      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      onChange={(e) => {
                        handleEdit(e.target.value, "inspector");
                      }}
                    >
                      <option></option>
                      {accounts &&
                        accounts.map((account) => (
                          <option
                            value={account.id}
                            selected={
                              data?.inspector
                                ? account.id == data.inspector
                                : account.manual_identifier ==
                                  localStorage.getItem("user_manual_identifier")
                            }
                          >
                            {`${account.first_name} ${account.last_name}`}
                          </option>
                        ))}
                    </select>
                    {validationErrors.includes("inspector") && (
                      <div className="ml-1 mt-1 text-pink-700">
                        Tarkastaja vaadittu
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => showAction(false)}
              >
                Sulje
              </button>
              <button
                className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => createExtinguisher()}
              >
                Tallenna muutokset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  ) : (
    <></>
  );
};

import {
  addDays,
  isToday,
  isWithinInterval,
  parseISO,
  startOfDay,
} from "date-fns";
import format from "date-fns/format";
import formatISO from "date-fns/formatISO";
import React, {
  SetStateAction,
  useEffect,
  useState,
  Dispatch,
  useCallback,
} from "react";
import LoadingIcons from "react-loading-icons";
import Select from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { api } from "../../api/axiosCalls";
import { ConfirmModal } from "../../components/common/ConfirmModal";
import { CustomerRow } from "../customers/CustomersView";
import { ExtinguisherRow } from "../extinguishers/ExtinguishersView";
import { LocationRow } from "./LocationsView";
import ReactDatePicker from "react-datepicker";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { InspectionReportDocument } from "../pdfReport/inspectionReport";
import { debounce } from "lodash";
import { filterByIncludes } from "../../api/filters/common";
import { subWeeks } from "date-fns/esm";
import { ThreePartActionButton } from "../../components/ThreePartActionButton";
import { isLiquidFireExtinguisherType } from "../../utils/isLiquidExtinguisher";

const styles = {
  inputEdit:
    "appearance-none block w-full text-gray-700 border border-gray-700 rounded py-1 px-1 mb-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-sm",
};

const ValueDisplay: React.FunctionComponent<{
  title: string;
  value: string;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
  highlighted?: boolean;
}> = ({
  title,
  value,
  changesDone,
  parentChangeNotifier,
  editHandler,
  keyName,
  highlighted,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  return (
    <>
      <h4 className="font-bold mt-3 text-xs">
        <span>{title}</span>
      </h4>
      {isEditing ? (
        <>
          <input
            className={`${styles.inputEdit} ${
              highlighted && "bg-pink-100"
            } text-xs`}
            type="text"
            defaultValue={value}
            onChange={(e) => {
              parentChangeNotifier(true);
              editHandler(e.target.value, keyName);
            }}
          />
          {highlighted && (
            <span className="text-pink-700 ml-2 text-xs">*pakollinen</span>
          )}
        </>
      ) : (
        <h1
          className="w-full h-6 cursor-pointer text-xs"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          {value}
        </h1>
      )}
    </>
  );
};

const ToggleDisplay: React.FunctionComponent<{
  title: string;
  value: string | number;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
}> = ({ title, value, changesDone, editHandler, parentChangeNotifier }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  return (
    <>
      <h4 className="font-bold mt-3 text-xs">{title}</h4>
      <input
        className={"mr-2 leading-tight text-xs"}
        type="checkbox"
        defaultChecked={value === "1" || value === 1}
        onChange={(e) => {
          parentChangeNotifier(true);
          editHandler(e.target.checked === true ? "1" : "0", "active");
        }}
      />
    </>
  );
};

const SelectDisplay: React.FunctionComponent<{
  title: string;
  value: string | number;
  changesDone: boolean;
  keyName: string;
  editHandler: (value: string, key: string) => void;
  parentChangeNotifier: Dispatch<SetStateAction<boolean>>;
  data: any[];
}> = ({
  title,
  value,
  changesDone,
  editHandler,
  parentChangeNotifier,
  data,
  keyName,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  useEffect(() => {
    !changesDone && isEditing && setIsEditing(false);
  }, [changesDone]);

  const resolvedCustomer =
    data.find((obj) => {
      return obj.id == value;
    })?.name || "";

  const styles = {
    control: (base: any) => ({
      ...base,
      minHeight: 32,
      width: 250,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    clearIndicator: (base: any) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  };

  return (
    <>
      <h4 className="font-bold mt-3 text-xs">{title}</h4>
      {isEditing ? (
        <>
          <Select
            options={[
              ...data.map((item) => ({
                value: item.id,
                label: `${item.name}`,
              })),
              { value: "", label: "Tyhjä" },
            ]}
            styles={styles}
            placeholder=""
            className="text-xs"
            onChange={(e) => {
              e && editHandler(e.value, keyName);
              parentChangeNotifier(true);
            }}
          />
        </>
      ) : (
        <h1
          className="w-full h-6 cursor-pointer text-xs"
          onClick={() => {
            setIsEditing(true);
          }}
        >
          {`${value} ${resolvedCustomer ? `(${resolvedCustomer})` : ""}`}
        </h1>
      )}
    </>
  );
};

export const SingleLocationView: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(addDays(new Date(), 90));

  const [billDate, setBillDate] = useState<Date>(new Date());

  const query = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const origin = query().get("origin") || null;

  const [location, setLocation] = useState<LocationRow | null>(null);
  const [extinguishers, setExtinguishers] = useState<ExtinguisherRow[] | []>(
    []
  );
  const [displayedExtinguishers, setDisplayedExtinguishers] = useState<
    ExtinguisherRow[] | []
  >([]);
  const [customers, setCustomers] = useState<CustomerRow[]>([]);
  const [extinguishersLoaded, setExtinguishersLoaded] = useState(false);
  const [changesDone, setChangesDone] = useState(false);

  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [locationEdits, setLocationEdits] = useState<LocationRow | {}>({});

  const [extinguisherFilter, setExtinguisherFilter] = useState("");

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  const [showBillSelection, setShowBillSelection] = useState(false);

  interface Sorter {
    value: string;
    direction: number;
  }

  const [sorter, setSorter] = useState<Sorter>({
    value: "",
    direction: 0,
  }); // 0 -> ASC, 1 -> DESC

  useEffect(() => {
    const filteredExtinguishers =
      extinguisherFilter.length > 2
        ? filterByIncludes(extinguishers, extinguisherFilter)
        : extinguishers;

    const sorted = filteredExtinguishers.sort((a: any, b: any) =>
      (
        sorter.direction === 0
          ? a[sorter.value] > b[sorter.value]
          : a[sorter.value] < b[sorter.value]
      )
        ? 1
        : (
            sorter.direction === 0
              ? b[sorter.value] > a[sorter.value]
              : b[sorter.value] < a[sorter.value]
          )
        ? -1
        : 0
    );

    setDisplayedExtinguishers(sorted);
  }, [extinguisherFilter, sorter, extinguishers]);

  const locationEditHandler = (value: string, key: string) => {
    if (key === "extra_information") {
      setLocationEdits({
        ...locationEdits,
        extra_information: value,
        extra_information_updated: formatISO(new Date()),
      });
    } else {
      setLocationEdits({ ...locationEdits, [key]: value });
    }
  };
  const fetchLocation = async () => {
    const locationResponse = await api.post("/locations/read", { id });
    if (locationResponse.status === 200) {
      const { data } = locationResponse;
      setLocation(data);
    }
  };

  const fetchAllCustomers = async () => {
    try {
      const allCustomersResponse = await api.get("/customers/");
      if (allCustomersResponse?.status === 200) {
        const { data } = allCustomersResponse;
        setCustomers(data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  useEffect(() => {
    fetchLocation();
  }, []);

  useEffect(() => {
    location && setLocationEdits(location);
  }, [location]);

  const fetchAllExtinguishersForLocation = async () => {
    const allExtinguishersResponse = await api.post(
      "/extinguishers/readresolvedforlocationwithstatus",
      { id }
    );
    if (allExtinguishersResponse.status === 200) {
      const { data } = allExtinguishersResponse;

      setExtinguishers(data);
      setExtinguishersLoaded(true);
    }
  };

  useEffect(() => {
    fetchAllExtinguishersForLocation();
  }, []);

  const deleteLocation = async () => {
    const deleteLocationResponse = await api.delete("/locations/delete", {
      data: { id: id },
    });
    if (deleteLocationResponse.status === 200) {
      navigate("/locations", { replace: true });
    } else {
      // error handling
    }
  };

  const editLocation = async () => {
    try {
      await schema.validate(locationEdits, { abortEarly: false });
      const editLocationResponse = await api.put("/locations/update", {
        location: locationEdits,
        id,
      });
      if (editLocationResponse.status === 200) {
        setChangesDone(false);
        fetchLocation();
      } else {
        // error handling
      }
    } catch (e) {
      setValidationErrors((e as any).inner.map((item: any) => item.path));
    }
  };

  let schema = yup.object().shape({
    name: yup.string().required(),
    address: yup.string().required(),
    zip: yup.string().required(),
    city: yup.string().required(),
  });

  const styles = {
    th: "border-b font-medium p-1 pl-1 pt-0 pb-2 text-gray-800 text-left text-xs select-none cursor-pointer",
    td: "border-b border-slate-300 p-1 pl-1 text-slate-800 text-xs",
    tdd: "border-b p-2 pl-2 text-slate-800 text-xs",
    labelDefault:
      "block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2",
    inputDefault:
      "appearance-none block w-full bg-white text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500",
    optionsBar: {
      display: "flex",
      flexDirection: "row" as "row",
      justifyContent: "space-evenly",
      width: "100%",
      height: 80,
      borderTop: 1,
      borderBottom: 1,
      borderColor: "black",
      margin: 2,
    },
  };

  const headers = [
    { title: "ID / Koodi", value: "qr" },
    { title: "Tila", value: "resolved_event" },
    { title: "Tyyppi", value: "type" },
    { title: "Merkki", value: "brand" },
    { title: "Koko", value: "size" },
    { title: "Malli", value: "model" },
    { title: "Valmistusvuosi", value: "year_of_manufacture" },
    { title: "Koeponnistusvuosi", value: "year_of_hydrostatic_test" },
    { title: "Nesteen vaihtovuosi", value: "year_of_liquid_replacement" },
    { title: "Tarkastusväli", value: "inspection_frequency" },
    { title: "Viimeisin tarkistus", value: "last_inspection_date" },
    { title: "Sijainti", value: "placement" },
  ];

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExtinguisherFilter(e.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 500), []);

  const updateBilling = async () => {
    try {
      const updateBillingResponse = await api.put("/locations/updatebilled", {
        date: billDate,
        id,
      });
      if (updateBillingResponse.status === 200) {
        setChangesDone(false);
        fetchLocation();
      } else {
        // error handling
      }
    } catch (e) {}
  };

  return (
    <>
      {location ? (
        <div className="shadow-sm overflow-hidden my-8">
          <main className="pt-8 mx-5">
            <div className="flex justify-between">
              <h4 className="text-black font-bold text-4xl">
                Kohde: {location.name || ""}
              </h4>
              <div>
                {showBillSelection ? (
                  <div className="p-2 bg-gray-100 border border-gray-400 flex flex-row z-50">
                    <div className="relative">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-50">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <ReactDatePicker
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-50"
                        locale="fi"
                        dateFormat="dd/MM/yyyy"
                        selected={billDate}
                        disabledKeyboardNavigation
                        onChange={(date) => {
                          date && setBillDate(startOfDay(date));
                        }}
                        popperClassName={"z-50"}
                      />
                    </div>

                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded  text-sm ml-4"
                      onClick={() => {
                        setShowBillSelection(false);
                        updateBilling();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                ) : (
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                    onClick={() => setShowBillSelection(true)}
                  >
                    Laskuta
                  </button>
                )}
              </div>
              <div>
                <button
                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm"
                  onClick={() => setShowDeleteConfirm(true)}
                >
                  Poista kohde
                </button>
              </div>
            </div>
            <div className="mt-4 -mb-3">
              <div className="not-prose relative bg-slate-100 rounded-xl overflow-x-auto">
                <div className="relative rounded-xl overflow-auto">
                  <div className="shadow-sm overflow-hidden my-8">
                    <div className="flex px-5 justify-between">
                      <div className="flex flex-col md:w-2/12">
                        <ValueDisplay
                          title={"Nimi"}
                          value={location.name}
                          keyName="name"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          highlighted={validationErrors.includes("name")}
                        />
                        <ValueDisplay
                          title={"Katuosoite"}
                          value={location.address}
                          keyName="address"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          highlighted={validationErrors.includes("address")}
                        />
                        <ValueDisplay
                          title={"Postinumero"}
                          value={location.zip}
                          keyName="zip"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          highlighted={validationErrors.includes("zip")}
                        />
                        <ValueDisplay
                          title={"Postitoimipaikka"}
                          value={location.city}
                          keyName="city"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          highlighted={validationErrors.includes("city")}
                        />
                        <ValueDisplay
                          title={"Yhteyshenkilö"}
                          value={location.contact_person}
                          keyName="contact_person"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ValueDisplay
                          title={"Puhelinnumero"}
                          value={location.phone_number}
                          keyName="phone_number"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <ToggleDisplay
                          title={"Aktiivinen"}
                          value={location.active}
                          keyName="active"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                        />
                        <SelectDisplay
                          title={"Asiakasnumero"}
                          value={location.customer_number}
                          keyName="customer_number"
                          editHandler={locationEditHandler}
                          changesDone={changesDone}
                          parentChangeNotifier={setChangesDone}
                          data={customers}
                        />
                        <h4 className="font-bold mt-3 text-xs">
                          Kohteen lisätiedot
                        </h4>
                        <div style={{ maxWidth: 250 }}>
                          <textarea
                            onChange={(e) => {
                              setChangesDone(true);
                              locationEditHandler(
                                e.target.value,
                                "extra_information"
                              );
                            }}
                            defaultValue={location.extra_information}
                            rows={5}
                            className="form-control block w-full px-3 py-1.5  text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none text-xs"
                          />
                        </div>

                        <span className="text-xs">
                          {location.extra_information_updated &&
                            `Tieto päivitetty ${format(
                              parseISO(location.extra_information_updated),
                              "dd/MM/yyyy"
                            )}`}
                        </span>

                        <h4 className="font-bold mt-3 text-xs">Laskutettu</h4>
                        <span style={{ minHeight: 80 }} className="text-xs">
                          {isWithinInterval(parseISO(location.billed_date), {
                            start: subWeeks(new Date(), 3),
                            end: addDays(new Date(), 29),
                          })
                            ? `${format(
                                parseISO(location.billed_date),
                                "dd/MM/yyyy"
                              )}`
                            : null}
                        </span>

                        <div className="flex flex-wrap justify-start">
                          <div>
                            <button
                              onClick={() =>
                                navigate(origin ? origin : "/locations")
                              }
                              className="bg-slate-400 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-2"
                            >
                              Takaisin
                            </button>
                          </div>
                          {changesDone && (
                            <>
                              <div>
                                <button
                                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-2"
                                  onClick={() => editLocation()}
                                >
                                  Tallenna muutokset
                                </button>
                              </div>
                              <div>
                                <button
                                  onClick={() => {
                                    setChangesDone(false);
                                  }}
                                  className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-2 text-sm mr-2"
                                >
                                  Peruuta muutokset
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col md:w-10/12 pl-2">
                        <div style={styles.optionsBar}>
                          <div className="relative mt-1 w-fit"></div>
                          <div className="flex flex-col mb-2">
                            <span className="text-left">Aikarajaus</span>
                            <div className="flex items-center">
                              <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-40">
                                  <svg
                                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                      clipRule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                                <ReactDatePicker
                                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-40"
                                  locale="fi"
                                  dateFormat="dd/MM/yyyy"
                                  selected={startDate}
                                  disabledKeyboardNavigation
                                  onChange={(date) => {
                                    setStartDate(date);
                                  }}
                                />
                              </div>
                              <span className="mx-4 text-gray-500">-</span>
                              <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none z-40">
                                  <svg
                                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                      clipRule="evenodd"
                                    ></path>
                                  </svg>
                                </div>
                                <ReactDatePicker
                                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 z-40"
                                  locale="fi"
                                  dateFormat="dd/MM/yyyy"
                                  selected={endDate}
                                  disabledKeyboardNavigation
                                  onChange={(date) => {
                                    setEndDate(date);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="relative mt-1">
                            <div className="flex flex-col mb-2">
                              {location && startDate && endDate && (
                                <PDFDownloadLink
                                  document={
                                    <InspectionReportDocument
                                      location={location}
                                      startDate={startDate}
                                      endDate={endDate}
                                    />
                                  }
                                  fileName={`raportti_${
                                    location.id
                                  }_${startDate?.toDateString()}-${endDate?.toDateString()}.pdf`}
                                >
                                  {({ blob, url, loading, error }) =>
                                    loading ? (
                                      "Ladataan dokumenttia..."
                                    ) : (
                                      <button className="mt-5 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 border border-green-700 rounded">
                                        Lataa raportti
                                      </button>
                                    )
                                  }
                                </PDFDownloadLink>
                              )}
                            </div>
                          </div>
                        </div>
                        <h4 className="text-black font-bold text-2xl mb-4">
                          Kohteen sammuttimet
                        </h4>
                        <div className="flex justify-between mb-2">
                          <div className="relative mt-1 flex justify-start">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg
                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                  clipRule="evenodd"
                                ></path>
                              </svg>
                            </div>
                            <input
                              type="text"
                              id="table-search"
                              className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block pl-10 p-2.5"
                              placeholder="Hae sammuttimia"
                              onChange={debouncedChangeHandler}
                            />
                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold px-4 border border-green-700 rounded ml-5">
                              Skannaa
                            </button>
                          </div>
                        </div>
                        {extinguishersLoaded ? (
                          <div style={{ overflow: "auto" }}>
                            <table className="border-collapse table-auto w-full text-sm">
                              <thead>
                                <tr>
                                  {headers.map((header) => (
                                    <th
                                      className={styles.th}
                                      key={header.value}
                                      onClick={() =>
                                        header.value &&
                                        setSorter({
                                          value: header.value,
                                          direction:
                                            header.value === sorter.value
                                              ? sorter.direction === 1
                                                ? 0
                                                : 1
                                              : 1,
                                        })
                                      }
                                    >
                                      {`${header.title} ${
                                        header.value === sorter.value
                                          ? sorter.direction === 0
                                            ? "⬇"
                                            : "⬆"
                                          : ""
                                      }`}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody className="bg-white">
                                {displayedExtinguishers.map((row) => (
                                  <tr
                                    key={row.id}
                                    className={`hover:bg-green-100 cursor-pointer ${
                                      isToday(
                                        parseISO(row.last_inspection_date)
                                      )
                                        ? "bg-green-300"
                                        : ""
                                    }`}
                                  >
                                    <td className={styles.td + " font-bold"}>
                                      {row.qr}
                                    </td>
                                    <td className={styles.td}>
                                      {row.resolved_event || "Ei tapahtumia"}
                                    </td>
                                    <td className={styles.td}>{row.type}</td>
                                    <td className={styles.td}>{row.brand}</td>
                                    <td className={styles.td}>{`${
                                      row.size || ""
                                    } ${row.unit || ""}`}</td>
                                    <td className={styles.td}>
                                      {row.model
                                        ? row.new_model
                                        : row.legacy_model}
                                    </td>
                                    <td className={styles.td}>
                                      {row.year_of_manufacture}
                                    </td>
                                    <td className={styles.td}>
                                      {row.year_of_hydrostatic_test}
                                    </td>
                                    <td
                                      className={
                                        styles.tdd +
                                        (isLiquidFireExtinguisherType(row.type)
                                          ? " bg-green-100"
                                          : " bg-gray-300 text-gray-300")
                                      }
                                    >
                                      {+row.year_of_liquid_replacement > 0
                                        ? row.year_of_liquid_replacement
                                        : "-"}
                                    </td>
                                    <td className={styles.td}>
                                      {row.inspection_frequency}
                                    </td>
                                    <td className={styles.td}>
                                      {row.last_inspection_date &&
                                        format(
                                          parseISO(row.last_inspection_date),
                                          "dd/MM/yyyy"
                                        )}
                                    </td>
                                    <td className={styles.td}>
                                      {row.placement}
                                    </td>
                                    <td>
                                      {id && (
                                        <ThreePartActionButton
                                          row={row}
                                          locationId={id}
                                          refreshAction={
                                            fetchAllExtinguishersForLocation
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className="flex items-center justify-center mt-10">
                            <div className="flex flex-col items-center">
                              <div className="bg-gray-200 rounded-lg border shadow-lg p-2 mb-5">
                                <LoadingIcons.TailSpin />
                              </div>
                              <div className="text-xl">Ladataan</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      ) : null}
      {showDeleteConfirm ? (
        <ConfirmModal
          showAction={setShowDeleteConfirm}
          header="Kohteen poisto"
          icon="danger"
          message="Oletko varma että haluat poistaa tämän kohteen?"
          confirmAction={() => deleteLocation()}
        />
      ) : null}
    </>
  );
};
